<template>
    <v-app-bar
        color="primary"
        dense
        dark
        clipped-left
        app 
        >

       <v-btn
          icon
          dark
           @click="toogleMenu"
        >
          <v-icon>mdi-menu  </v-icon>
        </v-btn>
          

      <v-toolbar-title
        
        class="ml-6 h1"
        >SMAFARMI
      </v-toolbar-title>
        
 
      <v-spacer></v-spacer>

      <v-btn
        class="ma-2"
        outlined
        v-if="!loggedIn"
        @click="openLoginDlg">
        Login
        </v-btn>
      <v-btn
          class="ma-2"
          outlined
          v-if="loggedIn"
          @click="logOut"
        >
          Logout
        </v-btn>
    </v-app-bar>

</template>

 
<script>
import EventBus from '../../services/event-bus.service';

export default {
  components: {
      // dlglogin,
  },
    data () {
        return {        
        }
  },

  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },

  methods:{
    openLoginDlg: function() {
        this.$emit('clicklogin')
    },

    logOut: function(){
      
        this.$store.dispatch('auth/logout');
        this.$store.dispatch('app/clearData'); 
    },

    toogleMenu: function(){
        EventBus.$emit('toggleMenu')
    }

  },

}
</script>
