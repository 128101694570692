<template>
<v-layout>
  <v-container>
   <appbarstart @clicklogin="clickLogin"></appbarstart>
    <v-dialog
      v-model="ctrlLogin.isVisible"
      :width="ctrlLogin.width"
    >
      <ctrlLoginTmp @close="ctrlLogin.isVisible = false"></ctrlLoginTmp>
    </v-dialog>
  </v-container>
</v-layout>
</template>

<script>
    
import appbarstart from '../components/appbars/AppBarStart.vue'

export default {
    name: 'Home',

    components: {
      appbarstart,
      ctrlLoginTmp: () => import('../components/ctrl/login.vue'),
    },

    data () {
        return { 
            ctrlLogin:{
                width: 500,
                isVisible: false,
            },

        }
    },
    computed: {
        loggedIn() {  
            return this.$store.state.auth.status.loggedIn;       
        },
    },
    methods:{

        clickLogin() {
            console.debug('clickLogin');
            this.ctrlLogin.isVisible = !this.ctrlLogin.isVisible;
        },

    },
    watch: { 
        loggedIn() { // watch it
            console.debug('login changed: ')
            if(this.loggedIn){
                this.ctrlLogin.isVisible = false;
                this.$router.push({ name: 'LoadCompany' })
            }
        }
    },
    mounted() {
        console.log('mounted');
        this.$store.dispatch('clearData');
        if(this.loggedIn)
            this.$router.push({ name: 'LoadCompany' })

        
    }
}
</script>